<template>
    <div class="card-info">
        <div class="card-title"></div>
        <van-field v-model="name"
                   :formatter="nameFormatter"
                   maxLength="20"
                   label="客户姓名"
                   placeholder="输入姓名"
                   input-align="right" />
        <van-field v-model="phoneNo"
                   :formatter="phoneFormatter"
                   maxLength="11"
                   label="联系电话"
                   placeholder="输入联系电话"
                   input-align="right" />
<!--        <van-field v-model="idCard"-->
<!--                   maxLength="18"-->
<!--                   label="身份证号"-->
<!--                   disabled-->
<!--                   placeholder="输入身份证号"-->
<!--                   input-align="right"-->
<!--                   readonly/>-->
<!--        <van-field-->
<!--                   maxLength="23"-->
<!--                   v-model="card"-->
<!--                   label="银行卡号"-->
<!--                   input-align="right"-->
<!--                   readonly-->
<!--                   disabled-->
<!--                   placeholder="请填写您的银行卡号" />-->
<!--        <van-uploader :preview-image="false"-->
<!--                      ref="bankImgup"-->
<!--                      style="display:none;"-->
<!--                      v-model="fileList"-->
<!--                      :after-read="afterRead" />-->
        <div class="header">分组</div>
        <div class="group-bg">
            <div class="group-cell" v-for="(thisList,index) in newArr" :key="index">
                <van-row  >
                    <van-col span="8" v-for="(thisItem,index1) in thisList" :key="index1">
                        <el-radio v-model="radio" :label="thisItem.dictKey" border size="medium" style="width: 90%;">{{thisItem.dictValue}}</el-radio>
                    </van-col>
                </van-row>
            </div>
        </div>
        <div class="footer">
            <van-button type="info" color="#3F7C53"
                        @click="commitAction">保存</van-button>
        </div>
    </div>
</template>

<script>
    import {Field, Button, Icon, Uploader, Toast} from 'vant';
    import { getDictByPage, sendBankCardOcr, getCustomerInfo,saveCustomerInfo } from "../../utils/api";
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "editCustomer",
        components: {
            [Field.name]: Field,
            [Button.name]: Button,
            [Icon.name]: Icon,
            [Uploader.name]: Uploader,
        },
        data () {
            return {
                isReadyOnly: true,
                name: '',
                card: '',
                fileList: [],
                phoneNo:'',
                idCard:'',
                radio:'',
                arr: [],
                newArr:[],
                customerNo:''
            }
        },
        watch: {

        },
        mounted () {
            this.customerNo = this.$route.query.customerNo;
            this.getPageInfo();
            this.toGetCustomerInfo();
            uwStatisticAction('/stationagent/editCustomer','修改联系人信息')
        },
        methods: {
            toGetCustomerInfo(){
                getCustomerInfo({
                    'customerNo':this.customerNo
                }).then(res=>{
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            let item = res.data.customerInfo;
                            this.phoneNo = res.data.customerInfo.phoneNo;
                            this.name = item.name;
                            // this.card = item.bankCardNo;
                            // this.idCard = item.idCard;
                            this.radio = item.customerGroup;
                            this.customerNo = item.customerNo;
                        }
                    }
                }).catch(()=>{
                })
            },
            getPageInfo () {
                this.arr = JSON.parse(common.getItem('customerGroup'));
                this.dataHandling();
            },
            dataHandling() {
                let arrLength = this.arr.length; // 数组长度
                let num = 3;  // 每页显示 10 条
                let index = 0;
                for (let i = 0; i < arrLength; i++) {
                    if (i % num === 0 && i !== 0) { // 可以被 10 整除
                        this.newArr.push(this.arr.slice(index, i));
                        index = i;
                    };
                    if ((i + 1) === arrLength) {
                        this.newArr.push(this.arr.slice(index, (i + 1)));
                    }
                };
            },
            IDNOBlur () {
                if (!this.idCard) {
                    return
                }
                if (!common.IdCardVerify(this.idCard)) {
                    this.$toast('身份证格式不正确')
                    return
                }
            },
            nameFormatter (val) {
                return val.replace(/[^\u4e00-\u9fa5.]/g, '')
            },
            phoneFormatter (val) {
                return val.replace(/[^\d]/g, "")
            },
            bankNoFormatter (val) {
                return val.replace(/[^\u4e00-\u9fa5.]/g, '')
            },
            idCardFormatter (val) {
                return val.replace(/[^\w.v]/ig, "")
            },
            bankInputBlur () {
                if (!this.card) {
                    return
                }
                if (!common.bankCardVerify(this.card.replace(/\s/g, ''))) {
                    this.$toast('请输入正确的银行卡号')
                    return
                }
            },
            chooseImg () {
            },
            rightChooseImg () {
                this.$refs.bankImgup.chooseFile()
            },
            afterRead (file) {
                console.log(file)
                // 大于15M
                if(file.file.size > 15 * 1024 * 1024){
                    Toast('图片太大，请选择小于15M的图片')
                    return
                }
                if(!common.imageVerify(file.file.type)){
                    this.fileList.splice(detail.index, 1)
                    Toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                if(file.file.size < 100 * 1024){
                    this.saveFile(file.content)
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                let _this = this
                reader.onload = function (e) {
                    let content= file.content //图片的src，base64格式
                    let img = new Image()
                    img.src = content
                    img.onload = function() {
                        common.compress(img, (dataURL)=>{
                            _this.saveFile(dataURL)
                            // console.log(dataURL)
                        })
                    }
                }
            },
            saveFile(dataURL) {
                let params = {
                    fileContent: dataURL
                }
                sendBankCardOcr(params).then(res => {
                    this.isOCR = true
                    if (res.code === 200) {
                        // let data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        let data
                        if (res.encryptedStatus === '1') {
                            data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        } else {
                            data = res.data

                        }
                        if (data.businessCode === 200) {
                            if (data.bankName) {
                                this.bankname = data.bankName
                            }
                            this.card = data.bankNumber.replace(/(^\s+)|(\s+$)/g, "")
                            this.fileNo = data.fileNo
                        }
                        this.fileNo = data.fileNo ? data.fileNo : ''
                    }
                }).catch(() => {

                });
            },
            commitAction () {
                if (!this.name) {
                    this.$toast('请填写姓名')
                    return
                }
                if (!this.phoneNo) {
                    this.$toast('请填写联系电话')
                    return
                }
                if (!common.phoneVerify(this.phoneNo.replace(/\s+/g, ""))) {
                    this.$toast('电话格式不正确')
                    return
                }
                let p = {
                    customerNo: this.customerNo,
                    stationNo: common.getItem('stationNo'),
                    name:this.name,
                    phoneNo:this.phoneNo,
                    customerGroup:this.radio
                }
                saveCustomerInfo(p).then(res => {
                    if (res.code === 200) {
                        let data
                        if (res.encryptedStatus === '1') {
                            data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        } else {
                            data = res.data
                        }
                        if (data.businessCode === 200) {
                            this.$router.go(-1)
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .card-info {
        background: #f5f5f5;
        height: 100%;
        font-size: 0.3rem;
        box-sizing: border-box;
        .card-title {
            padding: 0.3rem 0.3rem 0.2rem;
        }
        .header{
            margin-top: 0.2rem;margin-left: 0.2rem
        }
        .group-bg{
            background-color: white;padding-top: 0.1rem;margin-top: 0.2rem;padding-bottom: 0.3rem;
        }
        .group-cell{
            margin: 0.2rem 0.2rem 0 0.2rem;text-align: center
        }
        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            .van-button {
                width: 100%;
            }
        }
    }
</style>
<style lang="less">
    .card-info {
        .el-radio.is-bordered.is-checked {
            border-color: #3F7C53;
        }
        .el-radio__input.is-checked + .el-radio__label {
            color: #3F7C53;
        }
        .el-radio__input.is-checked .el-radio__inner {
            border-color: #3F7C53;
            background: #3F7C53;
            box-shadow: none !important;
        }
    }
</style>
